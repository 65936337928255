var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"float-center table-title"},[_c('strong',[_vm._v(" "+_vm._s(_vm.$getVisibleNames("mesh.egressprofilematter", true, "Asignaturas"))+" "),_c('InfoTooltip',{attrs:{"tooltip_text":`Estas ${_vm.$getVisibleNames(
          'mesh.egressprofilematter',
          true,
          'Asignaturas'
        )} son las específicas de los perfiles de egreso que pueden ser únicas o compartidas.`}})],1),_c('b-form-group',{staticClass:"mb-0",attrs:{"label":_vm.$getVisibleNames('mesh.egressprofile', false, 'Perfil de Egreso'),"label-for":"input-egress-profile-id","label-cols-sm":"3","label-align-sm":"center","label-size":"sm"}},[_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-form-select',{staticClass:"w-75",attrs:{"id":"input-egress-profile-id","options":_vm.filterEgressProfiles(),"value-field":"id","text-field":"professional_title","aria-describedby":"input-egress-profile-id-feedback","state":_vm.selected_egress_profile_id != null,"disabled":_vm.filterEgressProfiles().length == 0},on:{"change":() => (_vm.egress_profile_id = _vm.selected_egress_profile_id)},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}},[_vm._v(_vm._s(_vm.filterEgressProfiles().length > 0 ? String( "-- Seleccione un " + _vm.$getVisibleNames( "mesh.egressprofile", false, "Perfil de Egreso" ) + " --" ) : String( "No hay " + _vm.$getVisibleNames( "mesh.egressprofile", true, "Perfiles De Egreso" ) + " asociados a su usuario." )))])]},proxy:true}]),model:{value:(_vm.selected_egress_profile_id),callback:function ($$v) {_vm.selected_egress_profile_id=$$v},expression:"selected_egress_profile_id"}})],1),_c('b-form-invalid-feedback',{attrs:{"id":"input-egress-profile-id-feedback"}},[_vm._v("Seleccione un "+_vm._s(_vm.$getVisibleNames("mesh.egressprofile", false, "Perfil de Egreso"))+".")])],1)],1),_c('div',{staticClass:"float-left"},[(_vm.allows_crud && _vm.user.is_superuser)?_c('ButtonMedium',{attrs:{"tooltip_text":`Agregar ${_vm.$getVisibleNames(
        'mesh.egressprofilematter',
        false,
        'Asignatura'
      )}`,"text_button":`Agregar`,"click_button":() => _vm.$bvModal.show(`new-matter-modal`),"custom_class":`mt-3 mr-2 ml-3`,"icon":'plus',"variant":'primary'}}):_vm._e()],1),(_vm.selected_egress_profile_id)?_c('div',[_c('GenericBTable',{attrs:{"items":_vm.profileMatterFilter,"fields":_vm.fields,"current-page":_vm.currentPage,"per-page":_vm.perPage,"filter":_vm.input_search,"filterCustom":_vm.filterCustom,"filter-included-fields":_vm.filterOn,"show_pagination":true,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"busy":_vm.isBusy,"display_id":'profile-matter',"sort-direction":_vm.sortDirection},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"filtered":_vm.onFiltered,"emitChangeSearchField":_vm.changeInputSearch},scopedSlots:_vm._u([{key:"btn-download",fn:function(){return [(_vm.selected_egress_profile_id != null)?_c('GenericButtonDownload',{class:`ml-1 mr-2`,attrs:{"tooltip_text":`${_vm.$getVisibleNames(
            'mesh.egressprofilematter',
            false,
            'Asignatura'
          )} XLSX`,"click_button":_vm.DownloadExcel,"disabled_button":_vm.disabled_button_excel,"icon_excel":true}}):_vm._e()]},proxy:true},{key:"cell(name)",fn:function(row){return [_c('b-link',{attrs:{"to":{
            name: 'MatterProgramView',
            params: {
              matter_id: row.item.id,
            },
          }}},[_vm._v(" "+_vm._s(_vm.getMatterName(row.item.matter).toUpperCase())+" ")])]}},{key:"cell(egress_profile_id)",fn:function(row){return [(_vm.getEgressProfile(row.item.egress_profile))?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary",value:(
            _vm.getEgressProfile(row.item.egress_profile).professional_title
          ),expression:"\n            getEgressProfile(row.item.egress_profile).professional_title\n          ",modifiers:{"v-secondary":true}}]},[_vm._v(_vm._s(_vm.getEgressProfile(row.item.egress_profile).professional_title))]):_vm._e()]}},{key:"cell(weeks)",fn:function(row){return [(row.item.use_matter_values)?_c('span',[_vm._v(_vm._s((_vm.matters.find((x) => x.id == row.item.matter) || {}).weeks || 0))]):_c('span',[_vm._v(_vm._s(row.item.weeks))])]}},{key:"cell(credits)",fn:function(row){return [(row.item.use_matter_values)?_c('span',[_vm._v(_vm._s((_vm.matters.find((x) => x.id == row.item.matter) || {}).credits || 0))]):_c('span',[_vm._v(_vm._s(row.item.credits))])]}},{key:"cell(institutional_credits)",fn:function(row){return [(row.item.use_matter_values)?_c('span',[_vm._v(_vm._s((_vm.matters.find((x) => x.id == row.item.matter) || {}) .institutional_credits || 0))]):_c('span',[_vm._v(_vm._s(row.item.institutional_credits))])]}},{key:"cell(actions)",fn:function(row){return [(_vm.allows_crud)?_c('div',[_c('ButtonWrapper',{attrs:{"item_id":row.item.id},scopedSlots:_vm._u([{key:"btn-slot",fn:function(){return [(
                  _vm.allows_crud &&
                  !row.item.is_closed &&
                  ((_vm.user_position &&
                    [1, 2].includes(_vm.user_position.position) &&
                    [1, 2].includes(_vm.user.groups[0])) ||
                    _vm.user.is_superuser ||
                    _vm.$hasObjectPermission(
                      'mesh.faculty',
                      'change',
                      _vm.getFacultyId(row.item.career)
                    ) ||
                    _vm.$hasObjectPermission(
                      'mesh.career',
                      'change',
                      row.item.career
                    ))
                )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.auto.noninteractive",value:('Versiones'),expression:"'Versiones'",modifiers:{"v-secondary":true,"auto":true,"noninteractive":true}}],attrs:{"size":"sm"},on:{"click":function($event){return _vm.fetch_history(row)}}},[_c('b-badge',{attrs:{"variant":"light"}},[_vm._v(_vm._s(row.item.versions_count))])],1):_vm._e(),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.auto.noninteractive",value:(
                  `Ir al ${_vm.$getVisibleNames(
                    'manual.matter_program',
                    false,
                    'Programa de la Asignatura'
                  )}`
                ),expression:"\n                  `Ir al ${$getVisibleNames(\n                    'manual.matter_program',\n                    false,\n                    'Programa de la Asignatura'\n                  )}`\n                ",modifiers:{"v-secondary":true,"auto":true,"noninteractive":true}}],attrs:{"size":"sm","variant":"none"},on:{"click":function($event){return _vm.redirectToMatter(row.item.id)}}},[_c('b-icon',{attrs:{"icon":"eye","scale":"1"}})],1),(
                  _vm.allows_crud &&
                  !row.item.is_closed &&
                  ((_vm.user_position &&
                    [1, 2].includes(_vm.user_position.position) &&
                    [1, 2].includes(_vm.user.groups[0])) ||
                    _vm.user.is_superuser ||
                    _vm.$hasObjectPermission(
                      'mesh.faculty',
                      'change',
                      _vm.getFacultyId(row.item.career)
                    ) ||
                    _vm.$hasObjectPermission(
                      'mesh.career',
                      'change',
                      row.item.career
                    ))
                )?_c('ButtonSmall',{directives:[{name:"can",rawName:"v-can",value:('teaching.change_matter'),expression:"'teaching.change_matter'"}],class:``,attrs:{"tooltip_text":`Editar ${_vm.$getVisibleNames(
                  'mesh.egressprofilematter',
                  false,
                  'Asignatura'
                )}`,"click_button":() => _vm.$bvModal.show(`modal-edit-matter-${row.item.id}`),"icon":'square'}}):_vm._e(),(_vm.allows_crud && !row.item.is_closed && _vm.user.is_superuser)?_c('ButtonSmall',{class:`ml-1`,attrs:{"click_button":() => _vm.deleteMatter(row.item.id),"tooltip_text":`Eliminar ${_vm.$getVisibleNames(
                  'mesh.egressprofilematter',
                  false,
                  'Asignatura'
                )}`,"icon":'trash'}}):_vm._e()]},proxy:true}],null,true)}),_c('b-modal',{attrs:{"id":`modal-edit-matter-${row.item.id}`,"hide-footer":"","title":`Editar ${_vm.$getVisibleNames(
              'mesh.egressprofilematter',
              false,
              'Asignatura'
            )}`,"size":"xl"}},[_c('MatterForm',{attrs:{"Matter":row.item,"egress_profile_id":row.item.egress_profile,"show_delete_button":false},on:{"updated":_vm.updateMatter}})],1)],1):_vm._e()]}},{key:"row-details",fn:function(row){return [(_vm.allows_crud)?_c('b-card',[_c('b-table',{attrs:{"thead-class":"th-style","borderless":"","items":_vm.filterHistory(row.item.id),"fields":_vm.fields_history},scopedSlots:_vm._u([{key:"cell(date_time)",fn:function(cell){return [_vm._v(" "+_vm._s(_vm._f("FormatToDateTime")(cell.item.date_time))+" ")]}},{key:"cell(comment)",fn:function(cell){return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('b-form-textarea',{attrs:{"id":"input-comments","size":"sm"},model:{value:(cell.item.comment),callback:function ($$v) {_vm.$set(cell.item, "comment", $$v)},expression:"cell.item.comment"}})],1),_c('b-button',{directives:[{name:"can",rawName:"v-can",value:('reversion.change_revision'),expression:"'reversion.change_revision'"}],staticClass:"edit-button",attrs:{"size":"sm","variant":"none","title":"Guardar comentario"},on:{"click":function($event){return _vm.saveComment(cell.item.revision_id, cell.item.comment)}}},[_c('b-icon-box-arrow-in-down',{staticClass:"h4 mb-0"})],1)],1)]}},{key:"cell(active)",fn:function(cell){return [(_vm.ActiveMatter(cell.item.revision_id, row.item))?_c('div',[_c('b-icon',{attrs:{"icon":"check-circle-fill","scale":"1","variant":"primary"}})],1):_c('div',[_c('b-icon',{attrs:{"icon":"x-circle-fill","scale":"1"}})],1)]}},{key:"cell(actions)",fn:function(cell){return [_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm"},on:{"click":function($event){_vm.info(
                    _vm.filterHistoryData(cell.item.id),
                    cell.item,
                    $event.target
                  )}}},[_vm._v(" Ver versión ")])]}}],null,true)})],1):_vm._e()]}}],null,false,3092009957)}),_c('b-button',{staticClass:"mt-3 mr-1",attrs:{"size":"sm","variant":"secondary"},on:{"click":function($event){return _vm.$bvModal.show(`base-matter-modal`)}}},[_vm._v("Mostrar "+_vm._s(_vm.$getVisibleNames("teaching.matter", true, "Asignaturas Base")))])],1):_vm._e(),_c('b-modal',{attrs:{"id":`base-matter-modal`,"hide-footer":"","size":"xl"},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('div',{staticClass:"float-center mt-2 mb-2 table-title"},[_c('strong',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "teaching.matter", true, "Asignaturas Base" ).toUpperCase())+" ")]),_c('InfoTooltip',{attrs:{"tooltip_text":`Estas son las ${_vm.$getVisibleNames(
            'teaching.matter',
            true,
            'Asignaturas Base'
          )} que pueden ser utilizadas en varios Perfiles de Egreso, replicando sus resultados de aprendizaje.`}})],1)]},proxy:true}])},[(_vm.allows_crud)?_c('div',{staticClass:"float-left"}):_vm._e(),_c('GenericBTable',{attrs:{"items":_vm.matterFiltered,"fields":_vm.fields_old_matter,"filterCustom":_vm.filterCustom2,"filter":_vm.input_search,"sort-by":_vm.sortBy2,"sort-desc":_vm.sortDesc,"show_pagination":true,"search_filter":true,"columns_display":true,"allows_crud":_vm.allows_crud,"display_id":'base-matter'},on:{"update:sortBy":function($event){_vm.sortBy2=$event},"update:sort-by":function($event){_vm.sortBy2=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"emitChangeSearchField":_vm.changeInputSearch},scopedSlots:_vm._u([{key:"cell(study_units)",fn:function(row){return [_c('b-button',{staticStyle:{"max-height":"30px"},attrs:{"id":`popover-button-matter-${row.item.id}`,"size":"sm"}},[_c('b-badge',{attrs:{"variant":"light"}},[_vm._v(_vm._s(row.item.study_units.length))])],1),_c('b-popover',{attrs:{"target":`popover-button-matter-${row.item.id}`,"placement":"lefttop","triggers":"focus"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Unidades de estudio")]},proxy:true}],null,true)},[(row.item.study_units.length > 0)?_c('div',{staticClass:"campus-container"},_vm._l((_vm.getStudyUnitsName(row.item.study_units)),function(item){return _c('div',{key:item.id,staticClass:"campus-div"},[_vm._v(" "+_vm._s(item.name || item.full_sentence)+" ")])}),0):_c('div',[_vm._v("No cuenta con unidades de estudio")])])]}},{key:"cell(actions)",fn:function(row){return [(
            _vm.allows_crud &&
            ((_vm.user_position &&
              [1, 2].includes(_vm.user_position.position) &&
              [1, 2].includes(_vm.user.groups[0])) ||
              _vm.user.is_superuser)
          )?_c('button-edit',{directives:[{name:"can",rawName:"v-can",value:('teaching.change_matter'),expression:"'teaching.change_matter'"},{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.auto.noninteractive",value:({
            customClass: 'custom-tooltip',
          }),expression:"{\n            customClass: 'custom-tooltip',\n          }",modifiers:{"v-secondary":true,"auto":true,"noninteractive":true}}],attrs:{"title":`Editar ${_vm.$getVisibleNames(
            'teaching.matter',
            false,
            'Asignatura Base'
          )}`},on:{"click":function($event){return _vm.MatterNameModal(row.item)}}}):_vm._e(),_c('b-modal',{attrs:{"id":`modal-matter-name-${row.item.id}`,"hide-footer":"","title":`Editar ${_vm.$getVisibleNames(
            'teaching.matter',
            false,
            'Asignatura Base'
          )}`,"size":"lg"}},[_c('OldMatterForm',{attrs:{"OldMatter":row.item,"show_delete_button":_vm.allows_crud &&
              ((_vm.user_position &&
                [1, 2].includes(_vm.user_position.position) &&
                [1, 2].includes(_vm.user.groups[0])) ||
                _vm.user.is_superuser)},on:{"updated":function($event){return _vm.$bvModal.hide(`modal-matter-name-${row.item.id}`)},"delete":_vm.removeOldMatter}})],1)]}}])})],1),_c('b-modal',{attrs:{"id":_vm.infoModal.id,"size":"xl","hide-footer":""},on:{"hide":_vm.resetInfoModal},scopedSlots:_vm._u([(_vm.infoModal.title)?{key:"modal-title",fn:function(){return [_vm._v(" Fecha de modificación: "+_vm._s(_vm._f("FormatToDateTime")(_vm.infoModal.title.date_time)))]},proxy:true}:null],null,true)},[(_vm.infoModal.content)?_c('div',[_c('MatterProgram',{attrs:{"egress_profile_matter_id":_vm.infoModal.content.id,"allows_crud":false}})],1):_vm._e(),_c('div',[_c('b-button',{directives:[{name:"can",rawName:"v-can",value:('reversion.delete_revision'),expression:"'reversion.delete_revision'"}],attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.deleteChanges(_vm.infoModal.title, _vm.infoModal.content)}}},[_vm._v(" Eliminar versión ")]),_c('b-button',{staticClass:"float-right",attrs:{"size":"sm"},on:{"click":function($event){return _vm.$bvModal.hide('info-modal-matter')}}},[_vm._v(" Cancelar ")]),_c('b-button',{directives:[{name:"can",rawName:"v-can",value:('reversion.change_revision'),expression:"'reversion.change_revision'"}],staticClass:"float-right mr-5",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.applyChanges(_vm.infoModal.title, _vm.infoModal.content)}}},[_vm._v(" Aplicar versión ")])],1)]),_c('b-modal',{attrs:{"id":`new-old-matter-modal`,"hide-footer":"","title":`Crear ${_vm.$getVisibleNames(
      'teaching.matter',
      false,
      'Asignatura Base'
    )}`,"size":"lg"}},[_c('OldMatterForm',{on:{"created":function($event){return _vm.$bvModal.hide(`new-old-matter-modal`)}}})],1),(_vm.allows_crud)?_c('b-modal',{attrs:{"id":"new-matter-modal","title":`Crear ${_vm.$getVisibleNames(
      'mesh.egressprofilematter',
      false,
      'Asignatura'
    )}`,"size":"xl","hide-footer":""}},[_c('b-form-group',{staticClass:"ml-5 mb-4",attrs:{"label":_vm.$getVisibleNames('mesh.egressprofile', false, 'Perfil de Egreso'),"label-for":"sort-by-select","label-cols-sm":"2","label-align-sm":"left","label-size":"sm"},scopedSlots:_vm._u([{key:"default",fn:function({ ariaDescribedby }){return [_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-form-select',{staticClass:"w-75",attrs:{"id":"sort-by-select","options":_vm.filterEgressProfiles(),"value-field":"id","text-field":"professional_title","aria-describedby":ariaDescribedby,"disabled":_vm.filterEgressProfiles().length == 0},on:{"change":_vm.forceRerender},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v(_vm._s(_vm.filterEgressProfiles().length > 0 ? String( "-- Seleccione un " + _vm.$getVisibleNames( "mesh.egressprofile", false, "Perfil de Egreso" ) + " --" ) : String( "No hay " + _vm.$getVisibleNames( "mesh.egressprofile", true, "Perfiles De Egreso" ) + " asociados a su usuario." )))])]},proxy:true}],null,true),model:{value:(_vm.egress_profile_id),callback:function ($$v) {_vm.egress_profile_id=$$v},expression:"egress_profile_id"}})],1)]}}],null,false,594804752)}),(_vm.egress_profile_id)?_c('MatterForm',{key:_vm.componentKey,attrs:{"egress_profile_id":_vm.egress_profile_id},on:{"created":_vm.createMatter,"close_modal":_vm.createMatter}}):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }